  <template>
    <div>
        <breadcrumb name="Home Services" title="Settings" subtitle="Status" />
        <div class>
            <vx-card
                v-for="(item, index) in data"
                :key="index"
                :title="item.brand_id === -1 ? 'Show and Hide Services for Guest' : 'Show and Hide Services for '+item.brand_name"
                class="mb-base"
            >
                <vs-row>
                    <!--          <vs-col vs-lg="12" vs-sm="12" class="status">-->
                    <!--            <h6 class="title">Show/Hide Services for {{item.brand_id === -1 ? 'Guest' : item.brand_name}}</h6>-->
                    <!--          </vs-col>-->
                    <vs-col vs-lg="5" vs-sm="12">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">Advance Plus</td>
                                <td class="p-4">
                                    <vs-switch v-model="item.advance_plus_as_service" />
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Road Side Assistance</td>
                                <td class="p-4">
                                    <vs-switch v-model="item.rsa_as_service" />
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Spare Parts</td>
                                <td class="p-4">
                                    <vs-switch v-model="item.spare_parts_as_service" />
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Trade-In</td>
                                <td class="p-4">
                                    <vs-switch v-model="item.trade_in_as_service" />
                                </td>
                            </tr>
                            <tr v-if="item.brand_id !== -1">
                              <td class="font-semibold p-4">Track Your Car</td>
                              <td class="p-4">
                                <vs-switch v-model="item.track_your_car_as_service" />
                              </td>
                            </tr>
                        </table>
                    </vs-col>
                    <vs-col vs-lg="5" vs-sm="12">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">Mansour Plus</td>
                                <td class="p-4">
                                    <vs-switch v-model="item.mansour_plus_as_service" />
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Installment Calculator</td>
                                <td class="p-4">
                                    <vs-switch v-model="item.installment_as_service" />
                                </td>
                            </tr>
                          <tr>
                            <td class="font-semibold p-4">Extended Warranty</td>
                            <td class="p-4">
                              <vs-switch v-model="item.extended_warranty_as_service" />
                            </td>
                          </tr>
                          <tr>
                            <td class="font-semibold p-4">Car History</td>
                            <td class="p-4">
                              <vs-switch v-model="item.car_history_as_service" />
                            </td>
                          </tr>
                        </table>
                    </vs-col>
                </vs-row>
            </vx-card>

            <div v-for="(item, index) in data" :key="index"></div>
            <div class="optionBtns">
                <vs-button
                    class="mr-5 save"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="confirmSaveData"
                >Save</vs-button>
                <vs-button class="ml-5 cancel" type="border" color="primary" @click="getData">Reset</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "../../../components/general/breadcrumb";

export default {
    components: { Breadcrumb },
    data() {
        return {
            setting: [],
            brands: [],
            data: [],
            line1: "",
            line2: "",
            brand_id: "0",
            i: null,
        };
    },
    methods: {
        confirmSaveData() {
            this.$vs.dialog({
                title: "Confirm",
                text: "You are going to save these data!",
                color: "primary",
                accept: () => this.saveData(),
                type: "confirm",
            });
        },
        async saveEmails() {
            let data = [...this.data];
            for (let i in data) {
                data[i].booking_service_emails = data[
                    i
                ].booking_service_emails.split(",");

                try {
                    await this.$httpAdmin.post("brand-configs/update", data[i]);
                } catch (e) {}
            }
        },
        async saveData() {
            this.$vs.loading();

            for (let i in this.data) {
                this.data[i].installment_as_service
                    ? (this.data[i].installment_as_service = "1")
                    : (this.data[i].installment_as_service = "0");
                this.data[i].dashboard_signs_as_service
                    ? (this.data[i].dashboard_signs_as_service = "1")
                    : (this.data[i].dashboard_signs_as_service = "0");
                this.data[i].rsa_as_service
                    ? (this.data[i].rsa_as_service = "1")
                    : (this.data[i].rsa_as_service = "0");
                this.data[i].mansour_plus_as_service
                    ? (this.data[i].mansour_plus_as_service = "1")
                    : (this.data[i].mansour_plus_as_service = "0");
                this.data[i].trade_in_as_service
                    ? (this.data[i].trade_in_as_service = "1")
                    : (this.data[i].trade_in_as_service = "0");
                this.data[i].extended_warranty_as_service
                    ? (this.data[i].extended_warranty_as_service = "1")
                    : (this.data[i].extended_warranty_as_service = "0");
                this.data[i].spare_parts_as_service
                    ? (this.data[i].spare_parts_as_service = "1")
                    : (this.data[i].spare_parts_as_service = "0");
                this.data[i].locations_as_service
                    ? (this.data[i].locations_as_service = "1")
                    : (this.data[i].locations_as_service = "0");
                this.data[i].booking_service_as_service
                    ? (this.data[i].booking_service_as_service = "1")
                    : (this.data[i].booking_service_as_service = "0");
                this.data[i].offers_as_service
                    ? (this.data[i].offers_as_service = "1")
                    : (this.data[i].offers_as_service = "0");
                this.data[i].service_schedul_as_service
                    ? (this.data[i].service_schedul_as_service = "1")
                    : (this.data[i].service_schedul_as_service = "0");
                this.data[i].car_history_as_service
                    ? (this.data[i].car_history_as_service = "1")
                    : (this.data[i].car_history_as_service = "0");
                this.data[i].track_your_car_as_service
                    ? (this.data[i].track_your_car_as_service = "1")
                    : (this.data[i].track_your_car_as_service = "0");

                try {
                    await this.$httpAdmin.post(
                        "brand-configs/update",
                        this.data[i]
                    );
                } catch (e) {
                    this.$vs.loading.close();
                    console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Error is occurred!\nCouldn't Save Data!",
                        color: "danger",
                    });
                }
            }
            this.$vs.loading.close();
            this.$vs.notify({
                title: "Saved",
                text: "Data is Saved Successfully",
                color: "primary",
            });
            this.getData();
        },
        getData() {
            this.$vs.loading();
            this.$httpAdmin
                .get(`/brand-configs`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.data = res.data.data;
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
    },
    watch: {
        brand_id(value) {
            this.brand = [];
            for (let i in this.data) {
                if (value == this.data[i].brand_id) {
                    this.i = i;
                    break;
                }
            }
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped lang="scss">
.body {
    border-radius: 10px;
    padding: 20px;
}

.optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
}

.input {
    width: 100%;
    margin: 0 !important;
}

label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
}

select {
    width: 45%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.status {
    margin-top: 50px;
}

.title {
    margin-bottom: 20px;
    margin-left: 20px;
}
</style>
